// /* You can add global styles to this file, and also import other style files */
// @import 

// "node_modules/bootstrap/dist/css/bootstrap.min.css",
// "src/styles/app.scss"
@import "primeicons/primeicons.css";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";

@font-face {
    font-family: 'Droid Arabic Naskh';
    src: url('../src/assets/fonts/Droid-Naskh-Regular.ttf');
}




$primary-color: #0a4b0ad2;
$secondary-color: #8F9779;
$tertiary-color: #d18f1a;
$lightPrimary-color: #6b9b6b;
$lightTertiary-color: #fbae1f;
$lightSecondary-color: #8F9779;
$shadow-color: #58b15873;
$background-color: #f7f7f7;
$button-color: #8F9779;
$font-color: #5e5e5e;
$secondaryFont-color: #1f472c;





*{
    font-family: 'Droid Arabic Naskh';
    font-size: 12px;

}


body{
    height: auto;
}
.p-menubar {
    padding: 0.3rem;
    background: #f8f9fa;
    color: #495057;
    border: 1px solid #dee2e6;
    border-radius: 3px;

    color: #168812;

}

.p-menubar .p-menuitem-link {
    padding: 0.5rem 0.5rem;
    color: #495057;
    border-radius: 0;
    transition: box-shadow 0.2s;
    user-select: none;
    font-size: 14px;
}

.p-menubar .p-menuitem-link .p-menuitem-text {
    color: #495057;
}

.p-menubar .p-menuitem-link .p-menuitem-icon {
    color: #6c757d;
    margin-right: 0.5rem;
    font-size: 12px;
}

.p-menubar .p-menuitem-link .p-submenu-icon {
    color: #6c757d;
}

.p-menubar .p-menuitem-link:not(.p-disabled):hover {
    background: #e9ecef;
}

.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #495057;
}

.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #6c757d;
}

.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #6c757d;
}

.p-menubar .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem #a6d5fa;
}
.p-menubar-root-list{
    margin-bottom: 0px !important;
}

@media screen and (min-width: 961px) {
    .p-element .p-menuitem {
        background-color: #CDE8E5 !important;
        margin-right: 8px !important;
        margin-left: 8px !important;
       padding: 0;
       max-width: 120px;
       min-width: 100px;

       margin-top: 2px;
       margin-bottom: 2px;
    
    
    }
     .p-menuitem-link
    {
        font-size: 18px;
        font-weight: 600;
        width: 100%;
        display: table;
        text-align: center;
    }

    .dashboard_padding{
padding: 20px;
    }
 
}


.p-menuitem-text{
    margin: 5px !important;
}
.p-datatable-table{
    border-spacing: 2px!important;
}

.p-calendar{
    width: 100%;
    height: 30px;

}
.p-sidebar-right {
    width: 30rem;
    height: 100%;
}
.p-sidebar-left {
    width: 30rem;
    height: 100%;
}

 i:first-of-type {
    margin-left: 8px;
    margin-right: 8px;
    right: 1rem;
    left: auto;
    color: #6b7280;
    cursor:pointer;
}

.p-input-icon-left > .p-inputtext {
    padding-left: 8px;
    padding-right: 8px;
    height: 30px;
    width: 100%;
   


}

.p-datatable .p-datatable-tbody > tr > td {
    text-align: left!important;
    border: 1px solid #e5e7eb;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
}


.p-dropdown {
    display: flex;
    height: 30px;
}

.p-dropdown-label{
    padding: 5px;
}

.p-treeselect .p-treeselect-label.p-placeholder{
    color: #6b7280;
    display: flex;
    height: 38px;
}
.p-treeselect-label{
    padding-top: 5px;
}


  .pImageApp{
    border-radius: 20px  !important;
    border-style: none;
  }
  
  .pImageApp > button{
    border-radius: 20px  !important;
    border-style: none;
  }

  .profile-image {
    position: relative;
    width: 100px;
    margin: auto;
    border-radius: 100% !important;
  
  }

  
  
.profile-image > p-avatar  {
    border: none;
    position: absolute;
    bottom: -20px;
    right: 35px;
    left: 50px;
  }

  .file-image  {
    position: relative;
    border-radius: 100% !important;
  }

  .file-image > p-avatar  {
    border: none;
  

  }
    


  .AppMainCard{
    border-top: solid 3px #0C836D;
    background-color: white;
     border-radius: 10px; 
      padding: 30px;
  }


  .AppDataCard{
   padding: 5px;
   border: solid 1px rgb(216, 216, 216);
   border-top: solid 3px #0C836D;
   border-radius: 10px;
  }


  .AppDataFile{
    padding: 5px;
    border: solid 1px rgb(216, 216, 216);
    border-top: solid 3px #0C836D;
    border-radius: 10px;
   }



   .AppCard1{
    background-color: #0c836dd2;
    border-radius: 5px;
    color: white;
    font-weight: 600;
    font-size: 24px;
    padding: 12px;
    margin-left: 2px;
    margin-right: 2px;
    margin-bottom: 25px;
   }

   .app-header .collapse-icon svg{
    font-size: 17px;
  }
   .app-header .collapse-icon {
    color: #fff;
    margin-top: 5px;
  }
  
  .app-header .collapse-icon:hover {
    color: #1890ff;
  }


  .app-header .AppCollapse {
    font-size: 23px;
    cursor: pointer;
    transition: all .3s,padding 0s;
  }
  
  .app-header .ant-avatar.ant-avatar-icon {
    background-color:transparent;
  
  }
  
  .app-header .ant-avatar.ant-avatar-icon svg{
    font-size: 15px;
  }
  
  
ngx-dropzone.expandable {
    overflow-y: auto !important;
    max-height: 500px !important;
    display: grid !important;
}

.tox-promotion {
    display: none;
}

span.tox-statusbar__branding a {
    display: none;
}

.in-img:hover {
    opacity: 1;
    .previewL {
        opacity: 1;
    }
}

.previewL {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 22px;
    width: 22px;
    position: absolute;
    color: #ffffff;
    cursor: pointer;
    opacity: 0;
}

.in-img img {
    width: 100% !important;
    height: 100% !important;
    -o-object-fit: cover;
    object-fit: cover;
}

.d-row {
    display: flex;
    flex-wrap: wrap;
    width: 100% !important;
    padding: 15px !important;
    padding-top: 0px !important;
    padding-left: 0px !important;
}

.overflow{
    overflow: auto;
    max-height: 320px;
}

.p-checkbox .p-checkbox-box.p-highlight {
    border-color: #0C836D;
    background: #36c4aa;
}

/* Make checkbox smaller */
.p-checkbox .p-checkbox-box {
    width: 18px; /* Adjust the width */
    height: 18px; /* Adjust the height */
}

/* For Bootstrap checkbox */
.form-check-input:checked {
    background-color: #0d6efd;
    border-color: #0d6efd;
}

/* Make checkbox smaller */
.form-check-input {
    width: 18px; /* Adjust the width */
    height: 18px; /* Adjust the height */
    transform: scale(0.8); /* Scale down */
}

.tableHead th{
    background-color: #0C836D;
    color: white;
    font-size: 12px;
}

.tableHead td{
    background-color: #0C836D;
    color: white;
}

.tableHead svg{
    color: white;
}


.p-sidebar .p-sidebar-header {
    justify-content: space-between;
    font-weight: bolder;
    font-size: larger;
}



//  .p-picklist .p-picklist-header .p-picklist-title {
// background-color: #0C836D;
// color: white;
// }

.AppPickList .p-picklist .p-picklist-header {
    background-color: #0C836D;
    color: white;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;


}



.AppPickList  button {
    border-radius: 5px;
    margin: 5px;
    background-color: #0C836D;
}


.AppPickList .p-picklist .p-picklist-list .p-picklist-item {
    margin-bottom: 6px;
    border-radius: 5px;
    background-color: #e4e4e477;
}



.AppPickList  .p-picklist .p-picklist-list .p-picklist-item.p-highlight {
    color: #ffffff;
    background-color:  #c9c9c9;
}

.p-picklist .p-picklist-list .p-picklist-item:hover {
    background-color: rgb(206, 206, 206);
}


.AppPickList ul{
    padding-left: 1rem;
    padding-right: 1rem;
}



.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: #0C836D;
    border-color: #0C836D;
    color: #ffffff;
}


.p-tabview a{
    background: #ffffff;
    color: #0C836D;
}


.dividerData{
    color:#0C836D;
    font-size: 24px;
}

// /* width */
// ::-webkit-scrollbar {
//     width: 20px;
//   }


.materialList .p-badge {
    background: #0C836D !important;
}


.ant-menu {

    font-size: 10px;

}





.p-password{
    width: 100%;
  }
  
  .p-password .p-icon {
    position: relative;
    cursor: pointer;
  }
  
  .p-inputtext {
    width: 100%;
  }
  
  .p-inputtext:enabled:hover {
    border-color: $primary-color;
  }
  
  .p-inputtext:enabled:focus {
    box-shadow: 0 0 0 0.2rem $lightPrimary-color;
    border-color: $primary-color;
  }
  
  p-password.ng-invalid.ng-dirty > .p-password > .p-inputtext {
    box-shadow: 0 0 0 0.1rem #e24c4c;
    border-color: #e24c4c;
  }



  .AppDivider{
    width: 100%;
    border-radius: 5px;
    background-color:#0C836D;
    color: white;
    font-weight: 700;
    padding: 5px;
}