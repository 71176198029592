.app_rtl{
    direction: rtl!important;
    // text-align: start !important;
   .ant-menu-submenu-arrow {
          position: static!important;
    }
    .ant-menu-submenu-title {
        padding-right: 24px!important;
        text-align: start !important;
    }

    .ant-menu-item {
        padding-right: 24px;
        text-align: start !important;
    }

    .ant-menu-submenu  span{
        margin-right: 4px!important;
    }
    .ant-menu-item  span{
        margin-right: 4px!important;
    }

    .table_td_rtl{
        text-align: right!important;
    }
   


    .p-button-icon-left {
        margin-left: 0.5rem!important;
    }
   
    .p-menubar .p-menubar-custom, .p-menubar .p-menubar-end
    {
        margin-right: auto !important;
        margin-left: 0 !important;

    }

    .p-datatable .p-datatable-tbody > tr > td {
        text-align: right!important;
        border: 1px solid #e5e7eb;
        border-width: 0 0 1px 0;
        padding: 1rem 1rem;
    }

    i:first-of-type {
        left: 0.75rem!important;
        right: auto !important;
        color: #6b7280;
    }

    .p-dropdown .p-dropdown-clear-icon {
        left: 3rem!important;
        right: auto !important;
    }


    .p-treeselect .p-treeselect-clear-icon {
        left: 3rem!important;
        right: auto !important;
    }
  
    .pi-chevron-right {
        rotate: 180deg;
    }


    .AppPickList .p-icon-wrapper {
        display: inline-flex;
        rotate: 180deg;
    }


    // .p-icon {
    //     transform: rotate(180deg);
    //     margin-left: 4px;
    // }
}